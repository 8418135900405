import { themeProps } from '../styles/theme';
import { VarName } from './varNames';

export const MOTION_LIGHT_USAGE_MAX_VAL = 10;
export const MOTION_OCCUPIED_VALUE_RESCALE = 0.001;

export const dataColours = {
  red: themeProps.colors.warningRed,
  orange: themeProps.colors.alertYellow,
  green: themeProps.colors.green,
  blue: themeProps.colors.cobalt,
  lightblue: themeProps.colors.infoBlue,
  grey: themeProps.colors.grey,
  violet: themeProps.colors.violet,

  // Colour blind traffic light
  // https://graphicdesign.stackexchange.com/questions/17446/is-there-a-good-way-to-establish-a-green-yellow-red-colour-scheme
  greenPastel: '#C0FFE0',
  orangeMuted: '#FF8400',
  redBlood: '#E00000',
};

export type BandParamsType = {
  upperBound: number;
  color: string;
  text: string;
  label: string;
  description?: string;
  lightBackground?: boolean;
  desired?: boolean;
};
// Data will be categorised into highest upperBound the value is below

const BACnetBands: BandParamsType[] = [
  {
    upperBound: 0,
    color: dataColours.red,
    text: 'BMS Offline',
    label: 'BMS Offline',
    desired: false,
  },
  {
    upperBound: Infinity,
    color: dataColours.green,
    text: 'BMS Active',
    label: 'BMS Active',
    desired: true,
  },
];

const batteryLevelPctBands: BandParamsType[] = [
  {
    upperBound: 5,
    color: dataColours.red,
    text: 'Depleted',
    label: 'Depleted',
    desired: false,
  },
  {
    upperBound: 30,
    color: dataColours.orange,
    text: 'Low',
    label: 'Low',
    desired: false,
  },
  {
    upperBound: 80,
    color: dataColours.green,
    text: 'OK',
    label: 'OK',
    desired: true,
  },
  {
    upperBound: Infinity,
    color: dataColours.green,
    text: 'Charged',
    label: 'Charged',
    desired: true,
  },
];

const co2Bands: BandParamsType[] = [
  {
    upperBound: 750,
    color: dataColours.green,
    text: 'Great air quality',
    description: 'Conditions are optimal for 100% productivity; viral risk is low.',
    label: 'Ideal',
    desired: true,
  },
  {
    upperBound: 950,
    color: dataColours.green,
    text: 'Acceptable air quality',
    description:
      'Productivity reduces by 15% at 950ppm; equivalent to CIBSE approved ventilation rate of 10l/s/person.',
    label: 'OK',
    desired: true,
  },
  {
    upperBound: 1400,
    color: dataColours.orange,
    text: 'Reduced air quality',
    description:
      'Productivity in complex tasks is reduced by 50% at 1400ppm; viral transmission risk is increased.',
    label: 'Marginal',
    desired: false,
  },
  {
    upperBound: 2000,
    color: dataColours.red,
    text: 'Approaching severe air quality',
    description:
      'Cognitive functions such as decision making and strategic thinking are significantly affected; viral transmission risk is high.',
    label: 'Poor',
    desired: false,
  },
  {
    upperBound: Infinity,
    color: dataColours.red,
    text: 'Air quality is at a critical level',
    description:
      ' In extreme cases this may cause headaches, sleepiness, increased heart rate and is above recommended CIBSE guidelines; risk of viral transmission is very high.',
    label: 'Unhealthy',
    desired: false,
  },
];

const illuminanceArbBands: BandParamsType[] = [
  {
    upperBound: 0,
    color: '#333',
    text: 'Dark',
    label: 'Dark',
  },
  {
    upperBound: Infinity,
    color: '#eee',
    text: 'Light',
    label: 'Light',
    lightBackground: true,
  },
];

const motionOccupancyBands: BandParamsType[] = [
  {
    upperBound: 0.2,
    color: dataColours.green,
    text: 'Available',
    description: 'Available according to chosen motion thresholds',
    label: 'Available',
    desired: true,
  },
  {
    upperBound: 0.5,
    color: dataColours.green,
    text: 'Recent (Light)',
    description: 'Recently lightly used according to chosen motion thresholds',
    label: 'Recent (Light)',
    desired: false,
  },
  {
    upperBound: 0.8,
    color: dataColours.orange,
    text: 'Recent',
    description: 'Recently used according to chosen motion thresholds',
    label: 'Recent',
    desired: false,
  },
  {
    upperBound: 1 + MOTION_LIGHT_USAGE_MAX_VAL * MOTION_OCCUPIED_VALUE_RESCALE,
    color: dataColours.red,
    text: 'Occupied (Light)',
    description: 'Lightly occupied based on number of motion events',
    label: 'Occupied (Light)',
    desired: false,
  },
  {
    upperBound: Infinity,
    color: dataColours.red,
    text: 'Occupied',
    description: 'Occupied based on number of motion events and thresholds',
    label: 'Occupied',
    desired: false,
  },
];

const onlineStatusBands: BandParamsType[] = [
  {
    upperBound: -2,
    color: dataColours.blue,
    text: 'Retired',
    label: 'Retired',
  },
  {
    upperBound: -1,
    color: dataColours.lightblue,
    text: 'Rebooting',
    label: 'Rebooting',
  },
  {
    upperBound: 0,
    color: dataColours.red,
    text: 'Offline',
    label: 'Offline',
  },
  {
    upperBound: Infinity,
    color: dataColours.green,
    text: 'Online',
    label: 'Online',
    desired: true,
  },
];

const particulateMatterBands: BandParamsType[] = [
  {
    upperBound: 10,
    color: dataColours.green,
    text: 'Air is clean',
    description: 'WHO guidelines for Fine Particulate Matter (PM2.5) is < 10 µg/m³ annual mean',
    // link: https://www.who.int/news-room/fact-sheets/detail/ambient-(outdoor)-air-quality-and-health
    label: 'Clean',
    desired: true,
  },
  {
    upperBound: 20,
    color: dataColours.orange,
    text: 'Air has moderate polution',
    description:
      'WHO guidelines for Fine Particulate Matter (PM2.5) is < 25 µg/m³ for a 24-hour mean, not to exceed more than 3 days per year',
    // link: https://www.who.int/news-room/fact-sheets/detail/ambient-(outdoor)-air-quality-and-health
    label: 'Moderate',
    desired: false,
  },
  {
    upperBound: Infinity,
    color: dataColours.red,
    text: 'Air has high polution',
    description:
      'Life span may be decreased by 0.35 years for every 10 µg/m³ increase in average Fine Particulate Matter (PM2.5)',
    // link: https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4740125/
    // alternative texts 'overall mortality and mortality of cardiopulmonary diseases as well as lung cancer increased by 4%, 6% and 8%, respectively, for every 10 µg/m3 PM2.5 increase'
    label: 'High',
    desired: false,
  },
];

const pressuremBarBands: BandParamsType[] = [
  // Average surface air pressure is 1013 hPa
  {
    upperBound: 1005,
    color: dataColours.blue,
    text: 'Low',
    label: 'Low',
    desired: false,
  },
  {
    upperBound: 1021,
    color: dataColours.green,
    text: 'Typical',
    label: 'Typical',
    desired: true,
  },
  {
    upperBound: Infinity,
    color: dataColours.red,
    text: 'High',
    label: 'High',
    desired: false,
  },
];

const relativeHumidityBands: BandParamsType[] = [
  {
    upperBound: 20,
    color: dataColours.red,
    text: 'Too dry',
    description:
      'Can cause dry eyes, skin and throat irritation, and increase the risk of viral infection transmission.',
    label: 'Too dry',
    desired: false,
  },
  {
    upperBound: 40,
    color: dataColours.orange,
    text: 'A bit dry',
    description:
      'Those more susceptible may begin to suffer from dry eyes, itchiness and skin irritation',
    label: 'A bit dry',
    desired: false,
  },
  {
    upperBound: 60,
    color: dataColours.green,
    text: 'Optimal comfort',
    description: 'Optimal comfort and lowest risk of respiratory problems',
    label: 'Optimal',
    desired: true,
  },
  {
    upperBound: 75,
    color: dataColours.lightblue,
    text: 'A bit humid',
    description: 'Can lead to mould and fungi growth and increase the risk of respiratory problems',
    label: 'A bit humid',
    desired: false,
  },
  {
    upperBound: Infinity,
    color: dataColours.blue,
    text: 'Too humid',
    description:
      'Can cause overheating, dehydration, and increase the risk of respiratory infection',
    label: 'Too humid',
    desired: false,
  },
];

const temperatureBands: BandParamsType[] = [
  // link: https://www.cibse.org/knowledge/knowledge-items/detail?id=a0q20000006obXh
  {
    upperBound: 17,
    color: dataColours.blue,
    text: 'Cold',
    description:
      'Below 16ºC indoor temperature can cause discomfort and lack of concentration for occupants.',
    // suggestion: 'Suggested action: increase heating.',
    label: 'Cold',
    desired: false,
  },
  {
    upperBound: 20,
    color: dataColours.lightblue,
    text: 'Cool',
    description: 'Cool side of comfortable depending on season and individual preferences',
    label: 'Cool',
    desired: false,
  },
  {
    upperBound: 24,
    color: dataColours.green,
    text: 'Optimal comfort',
    description: 'A typical range comfortable to occupants.',
    // link: https://www.researchgate.net/publication/279542374_Room_temperature_and_productivity_in_office_work
    label: 'Comfortable',
    desired: true,
  },
  {
    upperBound: 27,
    color: dataColours.orange,
    text: 'Warm',
    description: 'Warm side of comfortable depending on season and individual preferences',
    label: 'Warm',
    desired: false,
  },
  {
    upperBound: Infinity,
    color: dataColours.red,
    text: 'Hot',
    description:
      'High temperature, could cause discomfort and lack of concentration for occupants.',
    // suggestion: 'Suggested action: turn heating down',
    label: 'Hot',
    desired: false,
  },
];

const VOCppbBands: BandParamsType[] = [
  {
    upperBound: 100,
    color: dataColours.green,
    text: 'Air is clean',
    // description: 'Values between 0 and 100 inform about improvement of the air quality over the past 24 hours.',
    label: 'Clean',
    desired: true,
  },
  {
    upperBound: 200,
    color: dataColours.orange,
    text: 'Noticable',
    // description: 'Values between 100 and 500 indicate a deterioration in air quality. Suggested action: increase fresh air flow.',
    label: 'Noticable',
    desired: false,
  },
  {
    upperBound: Infinity,
    color: dataColours.red,
    text: 'Smelly',
    // description: 'High TVOC will cause irritation of the eyes and respiratory tract, headaches, dizziness, visual disorders and memory impairment. Suggested action: increase fresh air flow.',
    label: 'Smelly',
    desired: false,
  },
];

export const motionUtilisationBands: BandParamsType[] = [
  {
    upperBound: 20,
    color: dataColours.green,
    text: 'Low Use',
    description: 'Low Use according to chosen motion thresholds',
    label: 'Low Use',
    desired: true,
  },
  {
    upperBound: 70,
    color: dataColours.orange,
    text: 'Light Use',
    description: 'Lightly used according to chosen motion thresholds',
    label: 'Light Use',
    desired: false,
  },
  {
    upperBound: Infinity,
    color: dataColours.red,
    text: 'Heavy Use',
    description: 'Heavy Use based on number of motion events and thresholds',
    label: 'Heavy Use',
    desired: false,
  },
];

export const frequencyHzBands: BandParamsType[] = [
  {
    upperBound: 0,
    color: dataColours.blue,
    text: 'Off',
    description: 'Off',
    label: 'Off',
    desired: false,
  },
  {
    upperBound: 25,
    color: dataColours.green,
    text: 'Low',
    description: 'Low',
    label: 'Low',
    desired: false,
  },
  {
    upperBound: 40,
    color: dataColours.orange,
    text: 'Mid',
    description: 'Mid',
    label: 'Mid',
    desired: true,
  },
  {
    upperBound: Infinity,
    color: dataColours.red,
    text: 'High',
    description: 'High',
    label: 'High',
    desired: false,
  },
];

export const outputLevelPctBands: BandParamsType[] = [
  {
    upperBound: 40,
    color: dataColours.green,
    text: 'Low',
    description: 'Low',
    label: 'Low',
    desired: false,
  },
  {
    upperBound: 70,
    color: dataColours.orange,
    text: 'Mid',
    description: 'Mid',
    label: 'Mid',
    desired: true,
  },
  {
    upperBound: Infinity,
    color: dataColours.red,
    text: 'High',
    description: 'High',
    label: 'High',
    desired: false,
  },
];

export const varNameBandParams: Record<VarName, BandParamsType[] | null> = {
  unknown: null,
  BACnetReads: BACnetBands,
  batteryLevelPct: batteryLevelPctBands,
  CO2ppm: co2Bands,
  clientsBLE: null, // Bands doesn't suit data type
  clientsWiFi: null, // Bands doesn't suit data type
  energyInkWh: null, // Bands doesn't suit data type
  illuminanceArb: illuminanceArbBands,
  motionEvent: motionOccupancyBands, // Bands for transformed data (occupancy from motion threshold)
  occSignatures: null, // Bands doesn't suit data type
  onlineStatus: onlineStatusBands,
  pressuremBar: pressuremBarBands,
  particulateMatter: particulateMatterBands,
  relativeHumidity: relativeHumidityBands,
  temperatureC: temperatureBands,
  VOCppb: VOCppbBands,
  frequencyHz: frequencyHzBands,
  outputLevelPct: outputLevelPctBands,
};

export const getDataBandParams = (varName: VarName, value: number): BandParamsType => {
  const bands = varNameBandParams[varName] ?? [
    {
      upperBound: Infinity,
      color: dataColours.blue,
      text: 'Unknown',
      label: 'Unknown',
    },
  ];
  // Filter out all bands below the value
  // get the lowest band remaining
  const bandParams: BandParamsType = bands
    .filter((params: BandParamsType) => value <= params.upperBound)
    .sort(
      (paramsA: BandParamsType, paramsB: BandParamsType) => paramsA.upperBound - paramsB.upperBound
    )[0];
  return bandParams;
};
