import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import AddIcon from '@mui/icons-material/Add';
import {
  getActiveMarker,
  getBleScanStatus,
  getBleSensors,
  getCurrentLocation,
  getLocationsById,
  getSelectedVars,
  getSensorsById,
  getSensorsByLocId,
  getSensorsByVarName,
  getUserLocation,
  getUserPosition,
  getBleLocSwitchStatus,
  getHighlightedItem,
  getClickedItem,
} from '../../state/selectors';
import { appSwitchBtnHandler } from '../../Shell/helpers';
import { themeProps } from '../../styles/theme';
import { VarName, varNameDetails } from '../../utils/varNames';
import SensorIcon from '../../styles/icons/SensorIcon';
import { getDataBandParams } from '../../utils/dataBandParams';
import Map from '../../Widgets/Map/Map';
import {
  setActiveMarker,
  setBleScanStatus,
  setCurrentLocation,
  setClickedItem,
} from '../../state/actions';
import useGlobalStyles from '../../styles/index';
import LFSwitchLogoIcon from '../../styles/icons/LFSwitchLogoIcon';
import { MapCentrePosition } from '../../Widgets/Map/mapHelpers';
import { isDataExpired } from '../../utils/functions';
import { findNearestSensor } from '../../utils/sensors';
import { SensorLatest } from '../../services/api';
import SourcesMenu from '../../components/SourcesMenu';

function AppSourceMenu() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalClasses = useGlobalStyles();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const bleSensors = useSelector(getBleSensors) ?? [];
  const sensorsByVarName = useSelector(getSensorsByVarName);
  const sensorsById = useSelector(getSensorsById);
  const currentLocation = useSelector(getCurrentLocation);
  const locSensorIds = useSelector(getSensorsByLocId).get(currentLocation) ?? [];
  const locationsById = useSelector(getLocationsById);
  const bleScanStatus = useSelector(getBleScanStatus);
  const selectedVars = useSelector(getSelectedVars);
  const sourceList = selectedVars.slice(0, 4); // Only allow up to 4 tiles
  const activeMarker = useSelector(getActiveMarker);
  const userLocation = useSelector(getUserLocation);
  const userPosition = useSelector(getUserPosition);
  const allowBleLocSwitch = useSelector(getBleLocSwitchStatus);
  const highlightedItem = useSelector(getHighlightedItem);
  const clickedItem = useSelector(getClickedItem);

  // Are we allowed to automatically select nearest sensor
  const autoSensorSelection = allowBleLocSwitch && !!bleSensors && !!userPosition;

  useEffect(() => {
    if (autoSensorSelection) {
      const nearestSensorId = bleSensors.find((s) => locSensorIds?.includes(s.id))?.id;
      // Get nearest sensor to user with activeMarker data
      const activeVarNameSensorIds =
        sensorsByVarName.get(activeMarker)?.filter((id) => locSensorIds.includes(id)) ?? [];
      if (nearestSensorId && activeVarNameSensorIds.includes(nearestSensorId)) {
        dispatch(
          setClickedItem({ id: nearestSensorId, varName: activeMarker, source: 'nearbyAuto' })
        );
      } else {
        const locSensors = activeVarNameSensorIds
          .map((id) => sensorsById.get(id))
          .filter(
            (sensor): sensor is SensorLatest =>
              sensor !== undefined &&
              !isDataExpired(sensor.data?.find((item) => item.varName === activeMarker)?.time ?? 0)
          );
        const targetPosition = userPosition;
        const targetSensor = targetPosition
          ? findNearestSensor(locSensors, targetPosition)
          : undefined;
        if (targetSensor) {
          dispatch(
            setClickedItem({ id: targetSensor.id, varName: activeMarker, source: 'nearbyAuto' })
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSensorSelection, activeMarker]);

  const activeItem = highlightedItem.id ? highlightedItem : clickedItem;
  const activeSensor = sensorsById.get(activeItem.id);
  // TODO - Show location summary data if no activeSensor selected
  const positionName = activeSensor ? activeSensor.name : undefined;

  const handleScan = () => {
    dispatch(setBleScanStatus(true));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.ReactNativeWebView?.postMessage(JSON.stringify({ startScan: 'startScan' }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleScan(), []);

  const handleClick = (hasSensorValue: boolean, varName: VarName, sensorId?: string) => {
    if (hasSensorValue && sensorId) {
      navigate(`/appDashboard/${sensorId}`);
      dispatch(setActiveMarker(varName));
    }
  };

  // set right location
  useEffect(() => {
    if (userLocation && userLocation !== currentLocation)
      dispatch(setCurrentLocation(userLocation));
  }, [userLocation, currentLocation, dispatch]);

  // show add source option for even sourcelist, to fill the grid container
  const showAddSourceBox = sourceList.length === 1 || sourceList.length === 3;

  return (
    <div
      style={{ overflowY: 'hidden', height: '100%', padding: '0' }}
      className={globalClasses.bodyContent}
    >
      <Box
        sx={{
          position: 'relative',
          height: '100%',
        }}
      >
        <Box sx={{ padding: '10px', position: 'absolute', top: '0', zIndex: 1000, width: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                checked
                onClick={() => appSwitchBtnHandler(dispatch)}
                color="success"
                checkedIcon={<LFSwitchLogoIcon fontSize="small" />}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <LocationOnIcon
              sx={{ alignSelf: 'center', fontSize: '50px', color: themeProps.colors.warningRed }}
            />
            <Box sx={{ alignSelf: 'center' }}>
              {positionName ? (
                <Typography variant="h4">{positionName}</Typography>
              ) : (
                <Typography variant="h4">
                  Select sensor / <MyLocationIcon sx={{ color: themeProps.colors.warningRed }} />
                </Typography>
              )}
              <Typography variant="body1">{locationsById.get(currentLocation)?.name}</Typography>
            </Box>
            <Box sx={{ marginLeft: '10px', alignSelf: 'center' }}>
              <IconButton
                sx={{ height: 'fit-content' }}
                onClick={handleScan}
                className={bleScanStatus ? globalClasses.spinIcon : ''}
              >
                <AutorenewIcon />
              </IconButton>
            </Box>
          </Box>

          <Grid container sx={{ textAlign: 'center', marginTop: '8px' }}>
            {sourceList.map((varName) => {
              const activeVarNameSensorIds =
                sensorsByVarName.get(varName)?.filter((id) => locSensorIds.includes(id)) ?? [];
              let dataSensor: SensorLatest | undefined;
              let isDifferentSensor = false; // different from title/activeSensor
              if (activeSensor && activeVarNameSensorIds.includes(activeSensor.id)) {
                dataSensor = activeSensor;
              } else {
                const locSensors = activeVarNameSensorIds
                  .map((id) => sensorsById.get(id))
                  .filter(
                    (sensor): sensor is SensorLatest =>
                      sensor !== undefined &&
                      !isDataExpired(
                        sensor.data?.find((item) => item.varName === varName)?.time ?? 0
                      )
                  );
                const targetPosition = autoSensorSelection ? userPosition : activeSensor?.position;
                const targetSensor = targetPosition
                  ? findNearestSensor(locSensors, targetPosition)
                  : undefined;
                if (targetSensor) {
                  dataSensor = targetSensor;
                  isDifferentSensor = true;
                }
              }
              const sensorDataValue = dataSensor?.data?.find(
                (item) => item.varName === varName
              )?.value;
              const hasSensorValue = !!(sensorDataValue !== undefined);

              const Icon = varNameDetails[varName].icon ?? SensorIcon;
              return (
                <Grid item xs={6} key={varName} sx={{ padding: '8px' }}>
                  <Box
                    sx={{
                      position: 'relative',
                      background: theme.palette.primary.main,
                      opacity: '0.85',
                      borderBottom:
                        varName === activeMarker
                          ? `solid 5px ${themeProps.colors.alertYellow}`
                          : 'none',
                    }}
                    className={globalClasses.appMenuBtn}
                    onClick={() => handleClick(hasSensorValue, varName, dataSensor?.id)}
                  >
                    {isDifferentSensor && (
                      <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                        <Typography
                          variant="caption"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          {dataSensor?.name} <LocationOnIcon />
                        </Typography>
                      </Box>
                    )}
                    <Box sx={{ alignSelf: 'center', margin: 'auto' }}>
                      <Box sx={{ display: 'flex' }}>
                        <Icon
                          style={{
                            color: hasSensorValue
                              ? theme.palette.text.primary
                              : theme.palette.primary.contrastText,
                          }}
                        />
                        <Typography
                          variant="body1"
                          sx={{
                            marginLeft: '5px',
                            color: hasSensorValue
                              ? theme.palette.text.primary
                              : theme.palette.primary.contrastText,
                          }}
                        >
                          {varNameDetails[varName].label}
                        </Typography>
                      </Box>
                      {hasSensorValue && (
                        <Box>
                          <Typography
                            variant="h4"
                            sx={{
                              marginTop: '10px',
                              color: getDataBandParams(varName, sensorDataValue).color,
                            }}
                          >
                            {parseFloat(sensorDataValue.toFixed(1))}{' '}
                            {varNameDetails[varName].metric}
                          </Typography>
                          <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
                            <ChevronRightIcon />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
              );
            })}

            {showAddSourceBox && (
              <Grid item xs={6} sx={{ padding: '8px' }}>
                <Box
                  sx={{
                    background: theme.palette.primary.main,
                    opacity: '0.65',
                    margin: 'auto',
                    display: 'block !important',
                  }}
                  className={globalClasses.appMenuBtn}
                >
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <SensorIcon />
                    <Typography
                      variant="body1"
                      sx={{
                        marginLeft: '5px',
                      }}
                    >
                      Add Source
                    </Typography>
                  </Box>

                  <SourcesMenu
                    iconOnlyBtn
                    customIcon={
                      <AddIcon
                        style={{ fontSize: '40px', background: 'none', marginTop: '-10px' }}
                      />
                    }
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>

        <Map mapCentrePosition={MapCentrePosition.bottom} />
      </Box>
    </div>
  );
}

export default AppSourceMenu;
