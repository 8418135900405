import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

import useGlobalStyles from '../../styles/index';
import { getActiveMarker, getSensorsById } from '../../state/selectors';

import { varNameDetails } from '../../utils/varNames';
import { themeProps } from '../../styles/theme';
import Feedback from './Feedback/Feedback';

import BackBtnIcon from '../../styles/icons/BackBtn';
import { appSwitchBtnHandler } from '../../Shell/helpers';
import LFSwitchLogoIcon from '../../styles/icons/LFSwitchLogoIcon';
import SelectedSensorPlot from '../../Widgets/ActiveSource/SelectedSensorPlot';
import { setClickedItem } from '../../state/actions';

dayjs.extend(isToday);

function AppDashboard() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalClasses = useGlobalStyles();
  const { sensorId } = useParams() as { sensorId: string };

  const activeMarker = useSelector(getActiveMarker);
  const sensorsById = useSelector(getSensorsById);

  const [showFeedbackPage, setShowFeedbackPage] = useState(false);

  useEffect(() => {
    dispatch(setClickedItem({ id: sensorId, varName: activeMarker }));
  }, [activeMarker, dispatch, sensorId]);

  const selectedSensorValue =
    sensorsById.get(sensorId)?.data?.find((item) => item.varName === activeMarker)?.value ?? 0;

  return (
    <div className={globalClasses.bodyContent}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <IconButton onClick={() => navigate('/appSourceMenu')}>
          <BackBtnIcon />
        </IconButton>
        <Box sx={{ display: 'flex' }}>
          <Switch
            checked
            onClick={() => appSwitchBtnHandler(dispatch)}
            color="success"
            checkedIcon={<LFSwitchLogoIcon fontSize="small" />}
          />
        </Box>
      </Box>
      {!showFeedbackPage && (
        <>
          <Typography variant="h4" sx={{ textAlign: 'center' }}>
            {varNameDetails[activeMarker].label}
          </Typography>
          <SelectedSensorPlot />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant={themeProps.btnVariant.default}
              sx={{
                borderRadius: '20px',
                background: theme.palette.primary.light,
                border: 'none',
              }}
              onClick={() => setShowFeedbackPage(true)}
            >
              How can we help you?
            </Button>
          </Box>
        </>
      )}
      {showFeedbackPage && (
        <Feedback
          sensorId={sensorId}
          sensorValue={parseFloat(selectedSensorValue.toFixed(1))}
          reset={() => setShowFeedbackPage(false)}
        />
      )}
    </div>
  );
}

export default AppDashboard;
